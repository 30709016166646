import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IUser } from '../../_models/login.model';
import KcAdminClient from '@keycloak/keycloak-admin-client';
import { environment } from '../../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSb = new BehaviorSubject<Boolean>(false);
  userData = new BehaviorSubject<IUser | null>(null);
  kcAdminClient = new KcAdminClient({
    baseUrl: environment.baseAuthUrl,
  });

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private route: Router
  ) {}
  isAuth(): Boolean {
    return this.userSb.getValue();
  }

  checkAuthAndLogin(): Observable<void> {
    return this.oidcSecurityService.checkAuth().pipe(
      map((auth) => {
        if (!auth) {
          this.login();
        } else {
          this.getUserData();
        }
        this.userSb.next(auth?.isAuthenticated);
      })
    );
  }

  getUserData() {
    this.oidcSecurityService.getUserData().subscribe((data: IUser) => {
      if (data) this.userData.next(data);
    });
  }

  login() {
    this.oidcSecurityService.authorize();
  }
  async authenticate() {
    await this.kcAdminClient.auth({
      username: 'portal-admin-client',
      password: '123456789',
      grantType: 'password',
      clientId: 'admin-cli',
    });
  }
  async updateUserPassword(userId: string, newPassword: string) {
    await this.authenticate();

    return this.kcAdminClient.users.update(
      { id: userId, realm: 'master' },
      { credentials: [{ type: 'password', value: newPassword }] }
    );
  }
  async getUserProfile() {
    await this.authenticate();

    return this.kcAdminClient.users.getProfile();
  }
  async updateUserProfile(userId: string, profile: IUser) {
    await this.authenticate();
    return this.kcAdminClient.users.update(
      { id: userId, realm: 'master' },
      {
        email: profile.email,
        firstName: profile.given_name,
        lastName: profile.family_name,

        // requiredActions: [RequiredActionAlias.UPDATE_PROFILE],
      }
    );
  }
  getUserRoles(): string[] {
    let token: string = '';
    let decodedToken: any;
    this.oidcSecurityService.getAccessToken().subscribe((data) => {
      token = data;
      if (token) {
        decodedToken = jwtDecode(token);
      }
    }); // or getAccessToken()
    return decodedToken.realm_access.roles;
  }
}
