import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  lang!: string ;
  lang$ = new BehaviorSubject<string|null>(null);

  constructor() {}

  changLanguage(lang: string) {    
    this.lang = lang;
    this.lang$.next(lang);    
  }
}
